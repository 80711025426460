import React, { useState, useEffect } from "react";
import "./Spinner.css"; // Import the CSS file for spinner animation

const Spinner = ({ isLoading, isLoadingLong }) => {
  const [loadingTime, setLoadingTime] = useState(0);

  useEffect(() => {
    let timer;
    if (isLoadingLong) {
      timer = setInterval(() => {
        setLoadingTime((prevTime) => prevTime + 1);
      }, 5000);
    } else if (isLoading) {
      timer = setInterval(() => {
        setLoadingTime((prevTime) => prevTime + 5);
      }, 1000);
    } else {
      setLoadingTime(0);
    }

    return () => clearInterval(timer);
  }, [isLoading, isLoadingLong]);

  if (!isLoading && !isLoadingLong) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.spinnerContainer}>
        <div className="spinner"></div> {/* Use className for spinner */}
        <p style={styles.message}>
          {isLoadingLong
            ? `Thinking... This might take a few minutes. ${loadingTime} links scanned.`
            : `Thinking... This might take a few seconds. ${loadingTime} links scanned.`}
        </p>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  spinnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  message: {
    marginTop: "10px",
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#333",
  },
};

export default Spinner;
