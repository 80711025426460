import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import debounce from "lodash.debounce";
import ReactMarkdown from "react-markdown";
import "./ProductForm.css";

const ProductForm = ({ setRecommendations, sessionId, onSearchComplete, input, setInput, setIsLoading, setIsLoadingLong, setStoredInput, description }) => {
  const [specificSite, setSpecificSite] = useState();
  const [numLinks, setNumLinks] = useState(6);
  const [numRecommendations, setNumRecommendations] = useState(6);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);
  const [searchInput, setSearchInput] = useState(input);
  const [suggestions, setSuggestions] = useState([]);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);
  const [response, setResponse] = useState("");
  const [isEscPressed, setIsEscPressed] = useState(false);
  const [searchMade, setSearchMade] = useState(false);
  const [isOutsideClicked, setIsOutsideClicked] = useState(false);
  const fetchSuggestionsRef = useRef(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [searchDuration, setSearchDuration] = useState(0);
  const [activeTab, setActiveTab] = useState("newSearch"); // New state for active tab
  const [similarProductLink, setSimilarProductLink] = useState(""); // New state for similar product link
  const [questionInput, setQuestionInput] = useState(""); // New state for question input
  const [lastSearchInput, setLastSearchInput] = useState(""); // New state for storing the last search input
  const [searchMadeInTabs, setSearchMadeInTabs] = useState({
    newSearch: false,
    similarProduct: false,
    askQuestion: false,
  });

  const resetStates = () => {
    setSearchInput(""); // Clear the input field
    setSuggestions([]); // Remove the suggestions
    setIsOutsideClicked(false); // Reset the outside click state
  };

  const handleSearch = async (searchValue, isDeepSearch = false) => {
    if (isDeepSearch) {
      setIsLoadingLong(true);
    } else {
      setIsLoading(true);
    }
    setIsLoadingLocal(true);
    setIsFormSubmitted(true);
    setIsEscPressed(false); // Reset the esc press state
    const startTime = Date.now();

    try {
      const searchQuery = searchValue.trim();
      const endpoint = isDeepSearch ? 'deep_search' : 'search';
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
        params: {
          user_input: searchQuery,
          num_links_to_scan: numLinks,
          num_recommendations: numRecommendations,
          specific_site: specificSite,
          session_id: sessionId, // Pass session ID to maintain context
        },
      });
      const endTime = Date.now();
      setSearchDuration(((endTime - startTime) / 1000).toFixed(2));
      setRecommendations(response.data.recommendations);
      setResponse(response.data.description); // Set the description from the search API response
      onSearchComplete(); // Hide the form after search
      resetStates(); // Reset states after search
      setSearchMade(true); // Indicate that a search has been made
      setStoredInput(searchValue); // Store the input value
      setLastSearchInput(searchValue); // Store the last search input
      setSearchMadeInTabs((prev) => ({ ...prev, [activeTab]: true })); // Mark search as made in the current tab
    } catch (error) {
      console.error("Error fetching product recommendations:", error);
    } finally {
      setIsLoading(false);
      setIsLoadingLong(false);
      setIsLoadingLocal(false);
      setIsFormSubmitted(false);
      setIsEscPressed(false); // Reset the esc press state after search
    }
  };

  const fetchSuggestions = async (value) => {
    if (isFetchingSuggestions || isEscPressed) return;
    setIsFetchingSuggestions(true);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/suggestions`, {
        params: {
          partial_input: value,
          num_suggestions: 5,
        },
      });
      if (!isFormSubmitted) {
        setSuggestions(response.data.suggestions || []); // Ensure suggestions is an array
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]); // Set suggestions to an empty array on error
    } finally {
      setIsFetchingSuggestions(false);
    }
  };

  const debouncedFetchSuggestions = useRef(debounce(fetchSuggestions, 300)).current;

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    // Reset the outside click state when user types something
    setIsOutsideClicked(false);

    if (value.length === 0) {
      setIsEscPressed(false); // Reset the esc press state when input is cleared
    }

    // if (value.length > 0 && !isEscPressed) {
    //   debouncedFetchSuggestions(value);
    // } else {
    //   setSuggestions([]);
    // }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion);
    setSuggestions([]);
    handleSearch(suggestion); // Confirm that the suggestion will be used as the product to search for
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (searchInput.trim() === "") return; // Prevent submission if input is empty
    handleSearch(searchInput);
    resetStates(); // Reset states after form submission
  };

  const handleDeepSearchSubmit = (e) => {
    e.preventDefault();
    if (searchInput.trim() === "") return; // Prevent submission if input is empty
    handleSearch(searchInput, true);
    resetStates(); // Reset states after form submission
  };

  const handleOutsideClick = (e) => {
    if (!e.target.closest('.inputGroup')) {
      setSuggestions([]);
      setIsOutsideClicked(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setSuggestions([]);
      setIsEscPressed(true);
    }
  };

  const handleTextareaKeyDown = (e) => {
    if (e.key === "Enter" && !(e.shiftKey || e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      if (activeTab === "newSearch") {
        handleFormSubmit(e);
      } else if (activeTab === "similarProduct") {
        handleSimilarProductFormSubmit(e);
      } else if (activeTab === "askQuestion") {
        handleQuestionFormSubmit(e);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleShowMeClick = () => {
    handleSearch("Show me products based on the last question I asked.");
  };

  const handleSiteButtonClick = (site) => {
    setSpecificSite(site);
  };

  const handleTextareaInput = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight - 20}px`;
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSimilarProductSearch = async (link) => {
    setIsLoading(true);
    setIsLoadingLocal(true);
    const startTime = Date.now();

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/find_similar`, {
        params: {
          product_link: link,
          num_recommendations: numRecommendations,
          session_id: sessionId, // Pass session ID to maintain context
        },
      });
      const endTime = Date.now();
      setSearchDuration(((endTime - startTime) / 1000).toFixed(2));
      setRecommendations(response.data.recommendations);
      setResponse(response.data.description); // Set the description from the search API response
      onSearchComplete(); // Hide the form after search
      resetStates(); // Reset states after search
      setSearchMade(true); // Indicate that a search has been made
      setStoredInput(link); // Store the input value
      setLastSearchInput(link); // Store the last search input
      setSearchMadeInTabs((prev) => ({ ...prev, [activeTab]: true })); // Mark search as made in the current tab
    } catch (error) {
      console.error("Error fetching similar product recommendations:", error);
    } finally {
      setIsLoading(false);
      setIsLoadingLocal(false);
    }
  };

  const handleSimilarProductDeepSearch = async (link) => {
    setIsLoadingLong(true);
    setIsLoadingLocal(true);
    const startTime = Date.now();

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/find_similar`, {
        params: {
          url: link,
          num_recommendations: numRecommendations,
          session_id: sessionId, // Pass session ID to maintain context
        },
      });
      const endTime = Date.now();
      setSearchDuration(((endTime - startTime) / 1000).toFixed(2));
      setRecommendations(response.data.recommendations);
      setResponse(response.data.description); // Set the description from the search API response
      onSearchComplete(); // Hide the form after search
      resetStates(); // Reset states after search
      setSearchMade(true); // Indicate that a search has been made
      setStoredInput(link); // Store the input value
      setLastSearchInput(link); // Store the last search input
      setSearchMadeInTabs((prev) => ({ ...prev, [activeTab]: true })); // Mark search as made in the current tab
    } catch (error) {
      console.error("Error fetching similar product recommendations:", error);
    } finally {
      setIsLoadingLong(false);
      setIsLoadingLocal(false);
    }
  };

  const handleSimilarProductInputChange = (e) => {
    setSimilarProductLink(e.target.value);
  };

  const handleSimilarProductFormSubmit = (e) => {
    e.preventDefault();
    if (similarProductLink.trim() === "") return; // Prevent submission if input is empty
    handleSimilarProductSearch(similarProductLink);
  };

  const handleSimilarProductDeepSearchSubmit = (e) => {
    e.preventDefault();
    if (similarProductLink.trim() === "") return; // Prevent submission if input is empty
    handleSimilarProductDeepSearch(similarProductLink);
  };

  const handleQuestionInputChange = (e) => {
    setQuestionInput(e.target.value);
  };

  const handleQuestionFormSubmit = async (e) => {
    e.preventDefault();
    if (questionInput.trim() === "") return; // Prevent submission if input is empty
    setIsLoadingLocal(true);
    const startTime = Date.now();

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/question`, {
        params: {
          question: questionInput,
          session_id: sessionId, // Pass session ID to maintain context
        },
      });
      const endTime = Date.now();
      setSearchDuration(((endTime - startTime) / 1000).toFixed(2));
      setResponse(response.data.answer); // Set the answer from the question API response
      onSearchComplete(); // Hide the form after search
      resetStates(); // Reset states after search
      setSearchMade(true); // Indicate that a search has been made
      setStoredInput(questionInput); // Store the input value
      setQuestionInput(""); // Clear the input
      setSearchMadeInTabs((prev) => ({ ...prev, [activeTab]: true })); // Mark search as made in the current tab
    } catch (error) {
      console.error("Error fetching question response:", error);
    } finally {
      setIsLoadingLocal(false);
    }
  };

  return (
    <div className="productFormContainer">
      <div className="tabs" style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <span
          className={`tab ${activeTab === "newSearch" ? "active" : ""}`}
          onClick={() => handleTabClick("newSearch")}
          style={{ cursor: 'pointer', textDecoration: activeTab === "newSearch" ? 'underline' : 'none' }}
        >
          New Product
        </span>
        <span
          className={`tab ${activeTab === "similarProduct" ? "active" : ""}`}
          onClick={() => handleTabClick("similarProduct")}
          style={{ cursor: 'pointer', textDecoration: activeTab === "similarProduct" ? 'underline' : 'none', marginLeft: '20px' }}
        >
          Similar Product
        </span>
        <span
          className={`tab ${activeTab === "askQuestion" ? "active" : ""}`}
          onClick={() => handleTabClick("askQuestion")}
          style={{ cursor: 'pointer', textDecoration: activeTab === "askQuestion" ? 'underline' : 'none', marginLeft: '20px' }}
        >
          Ask Question
        </span>
      </div>
      <form onSubmit={activeTab === "newSearch" ? handleFormSubmit : activeTab === "similarProduct" ? handleSimilarProductFormSubmit : handleQuestionFormSubmit} className="form">
        {activeTab === "newSearch" && (
          <div className="inputGroup">
            <label className="label">
              <FaSearch className="icon" /> Find a Product:
            </label>
            <p className="description">Enter the product you're looking for.</p>
            <textarea
              value={searchInput}
              onChange={(e) => { handleInputChange(e); handleTextareaInput(e); }}
              className="input"
              required
              rows="1"
              placeholder={searchMadeInTabs.newSearch ? "" : 'Show me the best digital calipers 0.0005" resolution under $200'}
              onKeyDown={(e) => { handleKeyDown(e); handleTextareaKeyDown(e); }} // Add onKeyDown event to textarea
            />
            {searchInput.length > 0 && suggestions.length > 0 && !isLoadingLocal && !isEscPressed && (
              <ul className="suggestionsList">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="suggestionItem"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        {activeTab === "similarProduct" && (
          <div className="inputGroup">
            <label className="label">
              <FaSearch className="icon" /> Find a Similar Product:
            </label>
            <p className="description">Enter the link for the product you're trying to match.</p>
            <textarea
              value={similarProductLink}
              onChange={handleSimilarProductInputChange}
              className="input"
              required
              rows="1"
              placeholder={searchMadeInTabs.similarProduct ? "" : "https://www.grainger.com/product/29AD41"}
              onKeyDown={handleTextareaKeyDown} // Add onKeyDown event to textarea
            />
          </div>
        )}
        {activeTab === "askQuestion" && (
          <div className="inputGroup">
            <label className="label">
              <FaSearch className="icon" /> Ask a Question:
            </label>
            <p className="description">Ask a question about the products shown below.</p>
            <textarea
              value={questionInput}
              onChange={handleQuestionInputChange}
              className="input"
              required
              rows="1"
              placeholder={searchMadeInTabs.askQuestion ? "" : "Which one is cheapest?"}
              onKeyDown={handleTextareaKeyDown} // Add onKeyDown event to textarea
            />
          </div>
        )}
        <div className="buttonGroup" style={{ display: 'flex', gap: '10px' }}>
          {activeTab === "newSearch" ? (
            <>
            <button type="submit" className="button" disabled={isLoadingLocal}>Search</button>
            <button type="button" className="button deepSearchButton" disabled={isLoadingLocal} onClick={handleDeepSearchSubmit}>Deep Search</button>
            </>
          ) : activeTab === "similarProduct" ? (
            <>
            <button type="button" className="button" disabled={isLoadingLocal} onClick={handleSimilarProductDeepSearchSubmit}>Deep Search</button>
            </>
          ) : (
            <>
            <button type="submit" className="button">Submit</button>
            </>
          )}
        </div>
        {activeTab === "askQuestion" && response && (
          <div className="response-container">
            <ReactMarkdown>{response}</ReactMarkdown>
          </div>
        )}
        {activeTab !== "askQuestion" && (
          <div className="siteButtons" style={{ marginTop: '10px' }}>
            <div>Search Vendor:</div>
            <button type="button" onClick={() => handleSiteButtonClick("")} className={`button ${specificSite === "" ? "active" : ""}`}>All</button>
            <button type="button" onClick={() => handleSiteButtonClick("grainger.com")} className={`button ${specificSite === "grainger.com" ? "active" : ""}`}>Grainger</button>
            <button type="button" onClick={() => handleSiteButtonClick("digikey.com")} className={`button ${specificSite === "digikey.com" ? "active" : ""}`}>DigiKey</button>
            <button type="button" onClick={() => handleSiteButtonClick("mscdirect.com")} className={`button ${specificSite === "mscdirect.com" ? "active" : ""}`}>MSC Direct</button>
            <button type="button" onClick={() => handleSiteButtonClick("automationdirect.com")} className={`button ${specificSite === "automationdirect.com" ? "active" : ""}`}>Automation Direct</button>
            <button type="button" onClick={() => handleSiteButtonClick("amazon.com")} className={`button ${specificSite === "amazon.com" ? "active" : ""}`}>Amazon</button>
          </div>
        )}
        {description && activeTab !== "askQuestion" && (
          <div className="response-container">
            <div className="search-duration">Thought for {searchDuration} seconds...</div>
            <ReactMarkdown>{description}</ReactMarkdown>
            <button onClick={handleShowMeClick} className="button" style={{ marginBottom: "15px" }}>Show me</button>
          </div>
        )}
      </form>
    </div>
  );
};

export default ProductForm;
